.blog-container {
  display: flex;
  padding: 40px 70px;
  gap: 60px;
  justify-content: center;
}
.blog-side-bar {
  max-width: 400px;
  min-width: 240px;
}
.detailed-search {
  padding-top: 30px;
  gap: 20px;
  display: grid;
  justify-items: self-start;
  max-width: 500px;
  margin: auto;
}
.detailed-search .btn {
  width: 90px;
  margin: auto;
}
.blog-cards {
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 40px;
  padding-bottom: 100px;
  position: relative;
}

.blog-loading {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .blog-container {
    flex-direction: column;
    padding: 20px 10px;
  }
  .blog-cards {
  }
  .law-status ul {
    text-align: center;
  }
  .blog-side-bar {
    max-width: 100%;
  }
  .detailed-search {
    padding-top: 20px;
    justify-items: center;
    gap: 15px;
  }
  .detailed-search h4 {
    text-align: center;
  }
}
@media screen and (max-width: 840px) {
  .blog-cards {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .blog-cards {
    grid-template-columns: 1fr;
  }
}
