.hero-page {
  height: 40vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-page img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}