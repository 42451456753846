select {
    color: #999999;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    letter-spacing: 1px;
    font-size: 0.4rem;
    padding: 8px 15px;
    width: 100%;
    margin: 0 0 20px 0;
    text-transform: none;
    max-width: 100%;
    resize: none;
    outline: none;
  }