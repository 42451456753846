header {
  position: static;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition: all 0.4s ease-in-out;
}
.online-appointment a {
  color: #ffffff;
  background-color: #000000;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.online-appointment a:hover {
  background-color: #7b8da0;
}

.logo {
  width: 280px;
  position: relative;
}
nav {
  transition: all 0.4s ease-in-out;
  z-index: 4;
}
.nav-items {
  display: flex;
}
.nav-items li {
  list-style: none;
  margin-right: 20px;
  cursor: pointer;
}
.nav-items li a {
  color: #fff;
  font-size: 0.6rem;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}
.nav-items .active {
  color: #a1a1a1;
}
.nav-items li a:hover {
  color: #a1a1a1;
}

.nav-menu-button {
  display: none;
  position: relative;
  width: var(--button-width);
  height: var(--button-height);
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  z-index: 4;
}
.rectangle {
  position: absolute;
  height: var(--rectangle-height);
  background: black;
  border-radius: var(--rectangle-radius);
  transition: all var(--transition);
  background-color: #000;
}
.rectangle--small {
  width: calc(var(--button-width) / 2);
}
.rectangle--top {
  top: 0;
  left: 0;
  transform-origin: top left;
}
.rectangle--top.open {
  transform: translateX(var(--translate)) rotate(45deg);
}
.rectangle--middle {
  top: 50%;
  left: 0;
  width: var(--button-width);
  transform: translatey(-50%);
}
.rectangle--middle.open {
  transform: translatey(-50%) rotate(-45deg);
}
.rectangle--bottom {
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
}
.rectangle--bottom.open {
  transform: translateX(calc(-1 * var(--translate))) rotate(45deg);
}

nav.open {
  transform: translate(0, 0);
}
.rectangle.open {
  background-color: #fff;
}

@media screen and (max-width: 1100px) {
  .logo {
    width: 200px;
  }
}

@media screen and (max-width: 800px) {
  .nav-menu-button {
    display: block;
  }
  nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: black;
    transform: translate(0, -100%);
  }
  nav ul {
    display: block !important;
    margin-top: 40px;
  }
  nav ul li {
    margin-top: 30px;
    text-align: center;
  }
  .nav-items li a {
    font-size: 1rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 800px) {
  .nav-items li a {
    color: #a1a1a1;
  }
  .nav-items .active a {
    color: var(--secondary-color);
  }
}
