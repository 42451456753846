.Hero {
    padding: 50px 50px;
    position: relative;
  }

  .Hero img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    object-fit: cover;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .Hero-box {
    gap: 20px;
    margin: auto;
    z-index: 3;
    display: grid;
    justify-items: center;
    max-width: 700px;
  }
  @media screen and (max-width: 600px) {
    .Hero {
      padding: 30px 10px;
    }
    .Hero-box {
      gap: 10px;
    }
  }