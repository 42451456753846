form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
  }
  .form-text {
    font-size: 0.6rem;
    line-height: 1.6;
    z-index: 2;
    font-weight: 400;
  }
  input {
    font-size: 0.4rem;
    letter-spacing: 1px;
    text-transform: none;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 8px 15px;
    width: 100%;
    margin: 0 0 20px 0;
    max-width: 100%;
    resize: none;
    outline: none;
  }
  input:focus {
    border-bottom: 1px solid #000;
  }
  button {
    font-size: 0.5rem;
    letter-spacing: 2px;
    font-weight: 400;
    color: #a1a1a1;

    padding: 6px 25px;
    border: none;
    border-bottom: 1px solid #a1a1a1;
    background-color: transparent;
    z-index: 3;
    cursor: pointer;
  }
  button:hover {
    color: #000;
    background: #dcdcdc;
  }
  .hoverDark:hover {
    background: #000 !important;
    color: #dcdcdc !important;
  }
  @media screen and (max-width: 600px) {
    form {
      gap: 20px;
      margin-top: 10px;
    }
  }