// Open Sans font import
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media (min-width: 30em) {
  html {
    font-size: 125%;
  }
}

@media (min-width: 40em) {
  html {
    font-size: 150%;
  }
}

@media (min-width: 50em) {
  html {
    font-size: 175%;
  }
}

@media (min-width: 60em) {
  html {
    font-size: 200%;
  }
}

html {
  font-size: clamp(1.45rem, 0.75rem + 1.5vw, 2rem);
}

body {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-family: "Crimson Pro", serif;
  max-width: 800px;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 50px;
  line-height: 1.4;
  letter-spacing: 1px;
  text-align: center;
  color: rgb(255, 255, 255);
  z-index: 3;
}

h3 {
  font-family: "Crimson Pro", serif;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 2px;
  font-weight: 600;
  color: #fff;
  z-index: 2;
}

h4 {
  font-family: "Crimson Pro", serif;
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 1.5;
  color: var(--primary-color);
}

h5 {
  font-family: "Crimson Pro", serif;
  color: var(--primary-color);
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

h6 {
  font-family: "Crimson Pro", serif;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 0.5rem;
  line-height: 1.1;
  color: #fff;
  margin-bottom: 10px;
}

.text-md {
  font-size: 0.6rem;
  line-height: 1.4;
  z-index: 2;
  font-weight: 200;
}

li {
  list-style: none;
}

a {
  font-family: "Crimson Pro", serif;
  text-decoration: none;
}

button {
  font-family: "Crimson Pro", serif;
  font-family: "Open Sans", sans-serif;
}

input {
  font-family: "Crimson Pro", serif;
  font-family: "Open Sans", sans-serif;
}

select {
  font-family: "Crimson Pro", serif;
  font-family: "Open Sans", sans-serif;
}

textarea {
  font-family: "Crimson Pro", serif;
  font-family: "Open Sans", sans-serif;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

p {
  font-family: "Crimson Pro", serif;
}

.scroll-block {
  overflow: hidden;
}

.title-color-red {
  color: var(--primary-color);
}

.text-color-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.opacity-medium {
  opacity: 0.7;
  background: #020a20 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
}

.blog-card-content > * {
  margin-bottom: 1rem;
}

.blog-card-content ul {
  padding: 0 1rem;
}

@media screen and (min-width: 425px) {
  .blog-card-content > * {
    margin-bottom: 0.7rem;
  }
}

:root {
  --button-width: 20px;
  --button-height: calc(var(--button-width) * 0.8);
  --rectangle-height: calc(var(--button-width) * 0.1);
  --rectangle-radius: calc(var(--button-width) * 0.04);
  --translate: calc(var(--button-width) * 0.17);
  --transition: 0.65s;
  --primary-color: #353c4d;
  --secondary-color: #d4a971;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
