.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fade-in 0.6s forwards;
}

.team-member-name a {
  color: #020a20;
  font-size: 0.5rem;
  letter-spacing: 1px;
}

.team-member-name a:hover {
  color: var(--secondary-color);
}

.team-member-status {
  font-size: 0.6rem;
  letter-spacing: 1px;
  color: #020a20;
  line-height: 1.2;
}

.team-card-img {
  position: relative;
}

.team-card-img img {
  width: auto;
  height: 400px;
}

.mt-8 {
  margin-top: 10px;
}
.mb-8 {
  margin-bottom: 10px;
}
@media screen and (max-width: 600px) {
  .team-card-img img {
    width: auto;
    height: 300px;
  }
}
