.blog-card {
    display: flex;
    flex-direction: column;
    animation: fade-in 0.6s;
    max-width: 450px;
  }

  .blog-card-cover {
    max-inline-size: 100%;
    block-size: auto;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .blog-card-author {
    color: #a1a1a1;
    font-size: 0.4rem;
    letter-spacing: 1px;
    text-transform: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .blog-card-title {
    color: #020a20;
    font-size: 0.6rem;
    letter-spacing: 1px;
    margin: 5px 0 15px;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .blog-card-text {
    font-size: 0.5rem;
    color: #030b23b3;
    line-height: 23px;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    .blog-card {
      max-width: 100%;
    }
  }